import { styled } from '@mui/material/styles';
import { colors } from "../../../colors.css";
import {Box} from "@mui/material";

export const FluffyNav = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  "li": {
    "a": {
      color: `${colors.primary}`,
      textDecoration: "none",
      "&:hover": {
        color: `${colors.active}`
      }
    },
  },
  "svg": {
    "&:hover": {
      fill: `${colors.active}`
    }
  },
  "li:last-child": {
    marginRight: "0px",
  },
}));