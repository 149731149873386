import * as React from 'react';
import {Box, ImageList, ImageListItem, Stack, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {ArrowForward, ChevronRight, ExpandMore} from "@mui/icons-material";
import {getCollectionTitle} from "../../../global/utils/formatCollectionTitle";
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import {Button, Link} from "gatsby-theme-material-ui";
import Grid from "@mui/material/Unstable_Grid2";
import {FiCard, FiCardActionArea, FiCardContent, FiCardMedia} from "../ui/FullImageCard";
import SearchBar from "../shopify/searchBar";

const MegaMenuTreeItem = ({collection, handleClose, parentSlug = "", level = 0}: any) => {
    const title = getCollectionTitle(collection.store.title)

    return (
        <TreeItem
            nodeId={collection.id}
            sx={{paddingTop:0, "& .MuiTreeItem-iconContainer": {display: collection.linkedCollections?.length > 0 ? "flex" : "none !important"}}}
            label={
                // @ts-ignore
                <Link
                    onClick={collection.hideBreadcrumb ? () => {} : handleClose}
                    to={collection.hideBreadcrumb ? "#" : `/vins/${parentSlug}${collection.store.slug.current}`}
                    sx={{display:"flex", textDecoration: "none", width:"100%", height:25, lineHeight: 1.3}}
                >
                    {title}
                </Link>
            }

        >
            {collection.linkedCollections?.length > 0 && collection.linkedCollections.map((subCollection: any) => {
                return (
                    <MegaMenuTreeItem
                        key={subCollection.id}
                        collection={subCollection}
                        expand={level === 0 ? true : open}
                        handleClose={handleClose}
                        parentSlug={collection.hideBreadcrumb ? "" : `${collection.store.slug.current}/`}
                        level={level + 1}
                    />
                )
            })}
        </TreeItem >
    )
}

const MegaMenuTree = ({collection, handleClose}: any) => {

    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            defaultExpanded={[collection.id]}
            sx={{
                //height: 240,
                flexGrow: 1,
                maxWidth: 400,
                //overflowY: 'auto'
            }}
        >
            <MegaMenuTreeItem
                collection={collection}
                handleClose={handleClose}
            />
        </TreeView>
    )
}

const fallbackImage = "https://images.unsplash.com/photo-1495929427389-a6451f2e01fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
const MegaMenuGallery = ({title, collection, handleClose, order = 1, cols = 1, rows = 1, specificDescription}: any) => {
    return (
        <ImageListItem key={collection.id} cols={cols} rows={rows} sx={{order: order}}>
            <FiCard sx={{display:"flex", height:"100%"}}>
                <FiCardActionArea
                    onClick={handleClose}
                    to={collection.hideBreadcrumb ? "#" : `/vins/${collection.store.slug.current}`}
                >
                    <FiCardMedia
                        // @ts-ignore
                        height="125"  alt="Contemplative Reptile" image={collection.store.imageUrl ?? fallbackImage} title={collection.store.title}
                    />
                    <FiCardContent sx={{height:"100%"}}>
                        <Stack spacing={2} height={"100%"} justifyContent={"center"} alignItems={(specificDescription || collection.discardDescription) ? "flex-start" : "center"}>
                            <Typography gutterBottom variant="h5" component="h3" textTransform={"uppercase"}>
                                {title}
                            </Typography>
                            {(specificDescription || collection.discardDescription) &&
                                <Typography
                                variant="body1"
                                component="p"
                                >
                                    Les Portes d'Epicure ont l'exclusivité de la gamme Bernard Magrez depuis 2022, à l'exception
                                    évidemment des vins qui sont distribués par les négociants bordelais.
                                </Typography>
                            }
                        </Stack>
                    </FiCardContent>
                </FiCardActionArea>
            </FiCard>
        </ImageListItem>
    )
}

const MegaMenuContent = ({link, handleClose}:any) => {
    const colorCollection = link.page.linkedCollections.filter((collection: any) => collection.store.slug.current === "couleur")
    const countryCollection = link.page.linkedCollections.filter((collection: any) => collection.store.slug.current === "pays")
    //const automatedCollection = link.page.linkedCollections.filter((collection: any) => collection.store.slug.current === "pays")

    return (
        <Grid container spacing={5} rowGap={2}>
            <Grid xs={12}>
                <Stack justifyContent={"center"} alignItems={"center"}>
                    <Box width={500} maxWidth={"80%"}>
                        <SearchBar isInNav placeholder={"Rechercher un vin, un cépage, un vendeur, ..."}/>
                    </Box>
                </Stack>
            </Grid>
            {/* enable tree view
                <Grid xs={12} lg={4}>
                    <Stack
                        //direction={"row"}
                        width={"100%"}
                    >
                        <Button onClick={handleClose} to={`/search`} endIcon={<ArrowForward/>}>Tous nos vins</Button>
                        {link.page.linkedCollections?.length > 0 && link.page.linkedCollections.map((collection: any) => {
                            return (
                                <React.Fragment key={collection.id}>
                                    <MegaMenuTree
                                        collection={collection}
                                        expand={true}
                                        handleClose={handleClose}
                                    />
                                </React.Fragment>

                            )
                        })}
                        {link.page.collectionPages?.length > 0 && link.page.collectionPages.map((page: any) => {
                            const collection = link.page.linkedCollections?.find((collection: any) => collection.id === page.pageContext.id)
                            return (
                                <MenuItem onClick={handleClose} key={collection.id}>
                                    <Link to={page.path}>{collection.store.title}</Link>
                                </MenuItem>
                            )
                        })}
                    </Stack>
                </Grid>
            */}
            <Grid minWidth={"20%"} py={0}>
                <Stack spacing={2}>
                    <Typography variant={"h4"} component={"h2"} fontWeight={"normal"}>Les couleurs</Typography>
                    <ImageList sx={{ width: "100%"}} cols={1} rowHeight={125} gap={15}>
                        {colorCollection?.length > 0 &&
                            colorCollection.map((collection: any) => {
                                return collection.linkedCollections.map((subCollection: any) => {
                                    const title = getCollectionTitle(subCollection.store.title)
                                    return (
                                        <MegaMenuGallery
                                            key={subCollection.id}
                                            title={title}
                                            collection={subCollection}
                                            handleClose={handleClose}
                                        />
                                    )
                                })
                            })}
                    </ImageList>
                </Stack>
            </Grid>
            <Grid flexGrow={1} borderLeft={"1px solid #fff"} py={0}>
                <Stack spacing={2}>
                    <Typography variant={"h4"} component={"h2"} fontWeight={"normal"}>Les vins du monde</Typography>
                    <ImageList sx={{ width: "100%"}} cols={4} rowHeight={125} gap={15}>
                        {countryCollection?.length > 0 &&
                            countryCollection.map((collection: any) => {
                                if(collection.linkedCollections?.length > 0){
                                   return collection.linkedCollections.map((subCollection: any) => {
                                       const title = getCollectionTitle(subCollection.store.title)
                                       return (
                                           <MegaMenuGallery
                                               key={subCollection.id}
                                               title={title}
                                               collection={subCollection}
                                               handleClose={handleClose}
                                               order={2}
                                           />
                                       )
                                   })
                                }
                                const title = getCollectionTitle(collection.store.title)
                                return (
                                    <MegaMenuGallery
                                        key={collection.id}
                                        title={title}
                                        collection={collection}
                                        handleClose={handleClose}
                                        order={1}
                                        cols={4}
                                        rows={2}
                                        specificDescription={
                                         "Les Portes d'Epicure ont l'exclusivité de la gamme Bernard Magrez depuis 2022, à l'exception évidemment des vins qui sont distribués par les négociants bordelais."
                                        }
                                    />
                                )
                        })}
                    </ImageList>
                </Stack>
            </Grid>
            <Stack width={"100%"} justifyContent={"center"} alignItems={"center"} pt={4}>
                <Button size={"large"} variant={"outlined"} onClick={handleClose} to={`/search`} endIcon={<ArrowForward/>}>Voir tous nos produits</Button>
            </Stack>
        </Grid>
    )
}

export default MegaMenuContent