import * as React from "react";
import { Container } from "@mui/material";
import Header from "@global/layouts/header/header";
import Footer from "@global/layouts/footer/footer";
import {SkipNavContent, SkipNavLink} from "@atoms/shopify/skip-nav";
import AgeVerification from "@atoms/ui/ageVerification";

const Layout = (props) => {
  const { link, logo } = props.data?.sanityHeader || {};
  const { copyright, privacy, socialLinks } = props.data?.sanityFooter || {};
  return (
    <>
      <AgeVerification/>
      <Container id={"header"} sx={{position:"fixed", zIndex:1301, maxWidth:"100% !important", padding: {xs:0, md:0}}}>
          <Header
            link={link}
            logo={logo}
            navigation={props.data?.sanityHeader?.navigation}
          />
      </Container>
      {props.children}
      <Container id={"footer"}>
          <Footer
            navigation={props.data?.sanityFooter?.navigation}
            logo={props.data?.sanityFooter?.logo}
            copyright={copyright}
            privacy={privacy}
            socialLinks={socialLinks}
          />
      </Container>
    </>
  )
}
export default Layout