import * as React from "react"
import { wrap, increment, decrement, input } from "./numeric-input.module.css"
import {Add, ArrowDownward, ArrowUpward, Remove} from "@mui/icons-material";
import {IconButton} from "gatsby-theme-material-ui";
import {Button, Input, InputBase, Stack, Typography} from "@mui/material";
export function NumericInput({
  value,
  onIncrement,
  onDecrement,
  className,
  disabled,
  ...props
}) {
  return (
      <Stack direction={"row"} width={"100%"}>
          <IconButton
              disabled={disabled}
              color={"primary"}
              sx={{border:"solid 1px #fff", borderRadius: 0}}
              onClick={onDecrement}
          >
              <Remove/>
          </IconButton>
          <Button
              disabled={disabled}
              variant={"outlined"}
              fullWidth
              sx={{padding:0, borderLeft: "none", borderRight: "none"}}
              disableRipple
          >
              {/*<Typography component={"span"} width={"100%"} mb={0}>{value}</Typography>*/}
              <InputBase
                  {...props}
                  value={value}
                  color={"primary"}
                  sx={{mb: 0, width:"auto", height:"100%", textAlign:"center", color:"#fff"}}
                  slotProps={{input: {style:{textAlign:"center"}}}}
              />
          </Button>
          <IconButton
              disabled={disabled}
              color={"primary"}
              sx={{border:"solid 1px #fff", borderRadius: 0}}
              onClick={onIncrement}
          >
              <Add/>
          </IconButton>
      </Stack>
  )
}
