import * as React from "react";
import {Link} from "gatsby";
import { colors } from "../../../colors.css";
import { Box, Typography } from "@mui/material";
import { PrivacyNav } from "@molecules/privacyMenu/style";
import { PrivacyMenuPropTypes } from "@molecules/privacyMenu/propTypesValidation";
import { useLanguagesStateContext } from "@global/contexts/languages/languageContext";

export default function PrivacyMenu({links}) {
  const languageState = useLanguagesStateContext();
  const langSlug = languageState.languageSlug === "fr-ch" ? "" : `/${languageState.languageSlug}`;
  return (
    <PrivacyNav component={"nav"} >
      <Box component={"ul"} sx={{paddingLeft: "0px"}}>
        {links.map(link => {
          return (
            <Box key={link._key} component={"li"} sx={{display: "inline-block", marginRight: "10px"}}>
              <Link to={`${langSlug}/${link.page?.slug ? link.page.slug : ""}`}>
                <Typography sx={{color: colors.text}} variant="privacyMenu" component="p">{link.title}</Typography>
              </Link>
            </Box>
          )
        })}
      </Box>
    </PrivacyNav>
  );
}

PrivacyMenu.propTypes = PrivacyMenuPropTypes;