import * as React from "react";
import { Link } from "gatsby";
import { colors } from "../../../colors.css";
import {Box, Grid, Stack, Typography} from "@mui/material";
import Navigation from "../../../components/molecules/navigation/navigation";
import SocialMenu from "../../../components/molecules/socialMenu/socialMenu";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PrivacyMenu from "../../../components/molecules/privacyMenu/privacyMenu";

export default function Footer({logo, navigation, privacy, copyright, socialLinks}) {

  return (
    <Box px={4} py={4}>
      <Grid container maxWidth="lg" >
        {navigation &&
            <Grid item xs={12} md={12} sx={{paddingTop: "0px!important"}}>
              <Box display={"flex"} justifyContent={"center"} width={"100%"}>
                <Navigation links={navigation.menuContentList}/>
              </Box>
            </Grid>
        }
        {privacy &&
            <Grid item xs={6} md={6} sx={{paddingTop: "0px!important"}}>
              <PrivacyMenu links={privacy.menuContentList}/>
            </Grid>
        }
        <Grid item xs={12} sx={{paddingTop: "0px!important"}} mt={2}>
          <Box sx={{background: `${colors.fade}`, height: ".5px"}}></Box>
        </Grid>
        {/*
          <Grid item xs={6} sx={{paddingTop: "0px!important"}}>
            <Box paddingY={4}>
              {logo && (
                  <Link to="/">
                    Home
                    <GatsbyImage
                        alt={logo.alt || ``}
                        image={getImage(logo.gatsbyImageData)}
                    />
                  </Link>
              )}
            </Box>
          </Grid>
          <Grid item xs={6} sx={{paddingTop: "0px!important"}}>
            <SocialMenu socials={socialLinks}/>
          </Grid>
        */}
        <Grid item xs={12} md={12} sx={{paddingTop: "0px!important"}}>
          <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"center"}>
            <Typography
                sx={{color: colors.fade, marginTop: "23px", textAlign: "left",}}
                // @ts-ignore
                variant="privacyMenu"
                component="div"
            >
              {copyright}
            </Typography>
            <Typography
                sx={{color: colors.fade, marginTop: "23px", textAlign: "left", ml:"auto"}}
                fontSize={12}
                // @ts-ignore
                variant="privacyMenu"
                component="div"
                textTransform={"uppercase"}
            >
              Cultivé par <span style={{color:"red"}}>Les Tontons</span>
            </Typography>
          </Stack>

        </Grid>
      </Grid>
    </Box>
  )
}
