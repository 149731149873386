import { defaultLanguagesState } from "./languageContext";

export default function languageReducer(state, action) {
  switch (action.type) {
    case "set":
      return {
        ...defaultLanguagesState,
        ...action.payload
      };
    case 'update':
      if(!action.payload.i18n_id) {
        return {
          ...state,
          error: true,
        }
      }
      return {
        ...defaultLanguagesState,
        ...state,
        ...action.payload,
      };
    case 'translate':
      if(!action.payload.translations.length) {
        return {
          ...state,
          error: true,
        }
      }
      return {
        ...defaultLanguagesState,
        ...state,
        ...action.payload,
      };
    default:
      //throw new Error('Can not set state for languageReducer');
      return state;
  }
}