import { colors } from '../colors.css.ts'
import { createTheme } from "@mui/material";

export const fonts = {
  primary: 'Bon Vivant',
  secondary: 'PT Sans, sans-serif',
}

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
  },
  typography: {
    h1: {
      fontSize: '4em',
      fontFamily: fonts.primary,
    },
    h2: {
      fontSize: '3em',
      fontWeight: 800,
      lineHeight: 1.1,
      marginBottom: "1rem",
    },
    body1: {
      fontSize: '1em',
      fontWeight: 400,
      lineHeight: 1.65,
      marginBottom: "1rem",
      fontFamily: fonts.secondary,
    },
    body2: {
      fontSize: '1em',
      fontWeight: 400,
      lineHeight: 1.65,
      marginBottom: "1rem",
      fontFamily: fonts.secondary,
    },
    kicker: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.1,
      marginBottom: "8px",
      textTransform: "uppercase",
      fontFamily: fonts.primary,
    },
    button: {
      //lineHeight: 2,
      //marginTop: "5px",
    },
    fontFamily: [
      fonts.primary,
      fonts.secondary,
    ].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Bon Vivant';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('BonVivant-Serif'), url('/fonts/BonVivant-Serif.otf') format('opentype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Bon Vivant';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('BonVivant-SerifBold'), url('/fonts/BonVivant-SerifBold.otf') format('opentype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Bon Vivant';
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: local('BonVivant-Regular'), url('/fonts/BonVivant-Regular.otf') format('opentype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
        body: {
          fontFamily: fonts.secondary,
          backgroundColor: colors.background,
        }
    },
    MuiContainer:{
      variants: [
          {
            props: { variant: 'main' },
            style: {
              paddingTop:120
            }
          },
      ]
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,.7)",
          backdropFilter: "blur(5px)"
        }
      }
    },
    MuiTypography:{
      styleOverrides: {
        root: {
          marginBottom: 0,
          lineHeight: 1.2
        }
      },
      variants: [
          {
            props: { variant: 'button', component: "span"},
            style: {
              lineHeight:2,
              marginTop: "5px",
            }
          },
      ]
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: colors.primary,
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: colors.primary,
          background: colors.background,
        }
      }
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginBottom: 0,
          lineHeight: 2,
          paddingTop: 4
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: fonts.secondary,
          borderRadius: 0,
        }
      },
      variants: [
        {
          props: { variant: 'contained', role: 'link' },
          style: {
            backgroundColor: "rgba(0,0,0,.2)",
            color: "#fff",
            fontWeight: "bold",
            padding:"6px 8px"
          }
        },
      ]
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: fonts.secondary,
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: fonts.secondary,
        },
        filled: {
          color: colors.primary,
          //marginTop: "0 !important",
          //lineHeight: 1.2,
          //top:-10
        },
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginTop: "0 !important",
          fontFamily: fonts.secondary,
          '& .MuiTypography-root': {
            fontFamily: fonts.secondary,
          }
        },
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "rgba(255,255,255, .25)",
          '&:hover': {
            color: "rgba(255,255,255, .9)",
          }
        },
      }
    },
  },
});

export default theme;