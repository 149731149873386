import {defaultUserState, UserState} from "./userContext";
import axios from "axios";

export enum UserActionType {
  LOGIN = "login",
  LOGOUT = "logout",
  GET = "get"
}

type UserAction =
    | { type: UserActionType.LOGIN; token: string }
    | { type: UserActionType.LOGOUT }
    | { type: UserActionType.GET; payload: object }

export default function userReducer(state: UserState, action: UserAction) {
    switch (action.type) {
      case "login":
        return {
          ...defaultUserState,
          token: action.token
        }
      case 'get':
        return {
          ...defaultUserState,
          ...state,
          ...action.payload,
        };
      case 'logout':
        return defaultUserState
      default:
        throw new Error(`Action, ${action.type} not valid for userReducer`);
    }
}