import * as React from "react"
import { useContext, useEffect } from "react";
import userReducer, {UserActionType} from "./userReducer";
import {PageProps} from "gatsby";
import axios from "axios";

export type UserState = {
  token?: string,
  id?: string,
  firstName?: string,
  lastName?: string,
  acceptsMarketing?: boolean,
  email?: string,
  phone?: string,
}
export const defaultUserState: UserState = {}
export const UserStateContext = React.createContext(defaultUserState);
export const UserDispatchContext = React.createContext({});

export const UserProvider = ({children, pageContext}: PageProps) => {
  const [state, dispatch] = React.useReducer(userReducer, defaultUserState, undefined);

  async function authenticate(){
    try {
      const response = await axios.post("/api/user/authenticate", {email: "chautems.eric@gmail.com", password: "YrL2BeLuabVQu5B"})
      const accessToken = response.data.data.customerAccessTokenCreate.customerAccessToken.accessToken
      dispatch({
        type: UserActionType.LOGIN,
        token: accessToken
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    //handle pageContext update
    if(!state.token){
      authenticate()
    }
  }, [pageContext])

  useEffect(() => {
    //handle user state update
  }, [state])

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

export const useUserStateContext = () => {
  const context = useContext(UserStateContext);
  if(context === undefined) {
    throw new Error("UserStateContext cannot be accessible.");
  }
  return context;
}

export const useUserDispatchContext = () => {
  const context = useContext(UserDispatchContext);
  if(context === undefined) {
    throw new Error("UserDispatchContext cannot be accessible.");
  }
  return context;
}