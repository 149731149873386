import {styled} from "@mui/material/styles";
import {Card, CardActions, CardContent, CardMedia} from "@mui/material";
import {CardActionArea} from "gatsby-theme-material-ui";

export const FiCard = styled(Card)(({ theme }) => ({
    position: "relative",
    borderRadius:0,
}))
export const FiCardActionArea = styled(CardActionArea)(({ theme }) => ({
    position: "relative"
}))
export const FiCardActions = styled(CardActions)(({ theme }) => ({
    position: "relative"
}))
export const FiCardContent = styled(CardContent)(({ theme }) => ({
    position: "relative",
    backgroundColor: "transparent"
}))
export const FiCardMedia = styled(CardMedia)(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: "100%",
    "&::after": {
        content: '""',
        position:"absolute",
        top:0,
        left:0,
        width:"100%",
        height:"100%",
        backgroundColor: "rgba(0,0,0,.7)"

    }
}))

export default {
    FiCard,
    FiCardActionArea,
    FiCardActions,
    FiCardContent,
    FiCardMedia
};