import * as React from "react";
import {Box, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Stack, Typography} from "@mui/material";
import {ListItemButton} from "gatsby-material-ui-components";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
//@ts-ignore
import { useLanguagesStateContext } from "@global/contexts/languages/languageContext";
import {IconButton, Link} from "gatsby-theme-material-ui";
import {useLocation} from "@reach/router";
//@ts-ignore
import {isCurrentLink} from "@global/utils/linkHeleper";
import {ExpandLess, ExpandMore, StarBorder} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import {getCollectionTitle} from "../../../global/utils/formatCollectionTitle";
import {NavigationContext} from "../../../global/layouts/header/header";

const DrawerListItem = ({link, parentSlug = "", pl = 2, level = 0}: any) => {
    const [open, setOpen] = React.useState<boolean>(level === 0 ?? false);
    const navigation = React.useContext(NavigationContext);
    const title = getCollectionTitle(link.title)
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton sx={{ pl: pl}}>
                <Link
                    onClick={link.page.hideBreadcrumb ? () => {} : () => navigation.toggleMenu(false)}
                    to={link.page.hideBreadcrumb ? "#" : `/vins/${parentSlug}${link.page.store.slug.current}`}
                    sx={{display:"flex", textDecoration: "none", width:"100%", height:25, lineHeight: 1.3}}
                >
                    <ListItemText primary={title} primaryTypographyProps={{fontSize: 20}}/>
                </Link>
                {link.page.linkedCollections?.length > 0 && <IconButton onClick={handleClick}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {link.page.linkedCollections?.length > 0 && link.page.linkedCollections.map((collection: any) => {
                        const pageLink = {
                            title: collection.store.title,
                            page: collection
                        }
                        return (
                            <DrawerListItem
                                key={collection.id}
                                link={pageLink}
                                pl={4 + level}
                                level={level + 2}
                                handleClose={() => navigation.toggleMenu(false)}
                                parentSlug={link.page.hideBreadcrumb ? "" : `${link.page.store.slug.current}/`}
                            />
                        )
                    })}
                </List>
            </Collapse>
        </>
    )
}

const DrawerContent: React.FunctionComponent<{
    navItems: any[],
    logo: any,
}> = ({navItems, logo}) => {
    const location = useLocation()
    const navigation = React.useContext(NavigationContext);
    const languageState = useLanguagesStateContext();
    const langSlug = languageState.languageSlug === "fr-ch" ? "" : `/${languageState.languageSlug}`;

    return (
        <Stack pt={2}>
            <Stack direction={"row"} pl={2} pr={2}>
                {logo && (
                    <Link to={`/${languageState.languageSlug === "fr-ch" ? "" : languageState.languageSlug}`}>
                        <GatsbyImage
                            alt={logo.alt || ``}
                            // @ts-ignore
                            image={getImage(logo.asset.gatsbyImageData)}
                        />
                    </Link>
                )}
                <IconButton onClick={() => navigation.toggleMenu(false)} sx={{ml:"auto"}}><CloseIcon/></IconButton>
            </Stack>
            <Divider sx={{mt:2}}/>
            <List>
                {navItems?.map((link, index) => {
                    //const path = link.href
                    //const regex = path && matchGatsbyPath(path)
                    //const active = regex ? regex.test(location.pathname) : false
                    if(link.page.collectionPages?.length || link.page.subPages?.length || link.page.linkedCollections?.length){
                        return (
                            <DrawerListItem key={index} link={link} handleClose={() => navigation.toggleMenu(false)}/>
                        )
                    }
                    const currentUrl = `${langSlug}/${link.page?.slug ? link.page.slug : ""}`;
                    return (
                        <ListItem key={index} sx={{pl: 0}} onClick={() => navigation.toggleMenu(false)}>
                            <ListItemButton
                                selected={isCurrentLink(currentUrl, location)}
                                to={currentUrl ?? "#"}
                                sx={{
                                    //borderRadius: "0 25px 25px 0",
                                    "&.Mui-selected": {
                                        //backgroundColor: "var(--mui-palette-secondary-main)",
                                        "& div": {
                                            //color: "var(--mui-palette-secondary-contrastText)"
                                        }
                                    },
                                    "&.Mui-selected:hover": {
                                        //backgroundColor: "var(--mui-palette-primary-main)",
                                        "& span": {
                                            //color: "var(--mui-palette-common-white)"
                                        }
                                    }
                                }}
                            >
                                <ListItemText primary={link.title} primaryTypographyProps={{fontSize: 20}}/>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </Stack>
    )
};

const MobileNav: React.FunctionComponent<{
    links: any[],
    logo: any,
    open: boolean
    drawerWidth?: number | string
}> = ({links, logo, open, drawerWidth}) => {
    const navigation = React.useContext(NavigationContext);

    return (
        <Box component="nav">
            <Drawer
                anchor={"right"}
                variant="temporary"
                open={navigation.open}
                onClose={() => navigation.toggleMenu(!navigation.open)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    zIndex:9999,
                    display: {xs: 'block', sm: 'none'},
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        //backgroundColor: "var(--mui-palette-background-default)",
                    },
                }}
            >
                <DrawerContent navItems={links} logo={logo}/>
            </Drawer>
        </Box>
    )
}

export default MobileNav;