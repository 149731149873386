import * as React from "react"
import "./src/global/ressources/fonts/BonVivant-SerifBold.otf"
import Layout from "@global/layouts/layout/layout"
import { LanguageProvider } from "@global/contexts/languages/languageContext";
import {StoreProvider} from "@global/contexts/store/storeContext";
import "./src/global/styles/global.css"
import "./src/global/styles/reset.css"
import { Provider as JotaiProvider } from 'jotai'
import {UserProvider} from "@global/contexts/user/userContext";
// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
}

export const wrapRootElement = ({ element, props }) => (
    <StoreProvider>
        <UserProvider {...props}>
            <JotaiProvider>
                {element}
            </JotaiProvider>
        </UserProvider>
    </StoreProvider>
)

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <LanguageProvider pageContext={props.pageContext}>
        <Layout {...props}>{element}</Layout>
      </LanguageProvider>
    </>
  );
};