import * as React from "react"
import {IconButton} from "gatsby-theme-material-ui";
import {ShoppingBagOutlined} from "@mui/icons-material";
import {Badge} from "@mui/material";

type CartButtonProps = {
    quantity: number,
    handleClick: () => void
}
export function CartButton({ quantity, handleClick }: CartButtonProps) {

  return (
      <IconButton color={"primary"} onClick={handleClick}>
          <Badge
              invisible={!quantity}
              badgeContent={quantity}
              color="primary"
              slotProps={{
                  badge:{
                      //@ts-ignore
                      sx:{fontFamily:"Kumbh Sans, sans serif"}
                  }
              }}
          >
            <ShoppingBagOutlined/>
          </Badge>
      </IconButton>
  )
}
