import {styled} from "@mui/material/styles";
import {AppBar} from "@mui/material";
import {colors} from "../../../colors.css";

const paddingTransition = "0.4s";

export const HeaderAppBar = styled(AppBar)(({ theme }) => ({
  color: colors.primary,
  background: "none",
  boxShadow: "none",
  padding: "25px 0px 25px 0px",
  maxWidth:"95%",
  margin:"auto",
  [theme.breakpoints.down('md')]:{
      padding:"10px 0",
      maxWidth:"100%",
  },
  "&.oppened": {
    background: "white",
  },
  "#menu-logo": {
    paddingLeft: "0px",
    transition: `${paddingTransition}`,
  },
  "#menu-button": {
    paddingRight: "0px",
    transition: `${paddingTransition}`,
  },
  "&.oppened #menu-logo": {
    paddingLeft: "20px",
  },
  "&.oppened #menu-button": {
    paddingRight: "20px",
  },
}));