import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import {StoreContext} from "../../../global/contexts/store/storeContext";
import {useEffect} from "react";
import {Snackbar} from "@mui/material";

export default function ErrorAlerts() {
    const { checkout } = React.useContext(StoreContext)
    // @ts-ignore
    const userErrors = checkout?.userErrors
    const [open, setOpen] = React.useState(userErrors && userErrors.length > 0);

    useEffect(() => {
        setOpen(userErrors && userErrors.length > 0)
    },[userErrors])

    return (
        <>
            <Snackbar open={userErrors?.length && open} anchorOrigin={{ vertical:"top", horizontal:"right"}} sx={{top:{xs:100, md: 100}, bottom:{xs:"unset", md: "unset"}, maxWidth: "calc(100% - 25px)"}}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {userErrors?.length ? userErrors[0].message : ""}
                </Alert>
            </Snackbar>
        </>
    );
}
