import { styled } from '@mui/material/styles';
import { colors } from "../../../colors.css";
import {Box} from "@mui/material";

export const SocialNav = styled(Box)(({ theme }) => ({
  margin: "45px auto 0px auto",
  textAlign: "right",
  "li": {
    display: "inline-block",
    "a": {
      textDecoration: "none",
      "p": {
        "&:hover": {
          color: `${colors.active}`
        },
      }
    },
  },
  "li:last-child": {
    marginRight: "0px",
  },
}));