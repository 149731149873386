import * as React from "react"
import {StoreContext} from "../../../global/contexts/store/storeContext";
import {Box, Stack, Typography} from "@mui/material";
//@ts-ignore
import {LineItem} from "../../atoms/shopify/line-item";
//@ts-ignore
import {formatPrice} from "../../../global/utils/format-price";
import {
    table,
    totals,
    grandTotal,
    summary,
    collapseColumn,
    labelColumn,
    imageHeader,
    productHeader,
    emptyStateContainer,
    emptyStateLink,
} from "./cartSkeleton.module.css"
import {Suspense} from "react";
import {Button} from "gatsby-theme-material-ui";


const CartSkeleton = ({modal = false, handleClick = () => {} }) => {
    const { checkout, loading } = React.useContext(StoreContext)
    const isBrowser = typeof window !== `undefined`
    const emptyCart = checkout?.lineItems?.length === 0
    const subtotal = isBrowser && checkout?.subtotalPriceV2.currencyCode ? formatPrice(checkout?.subtotalPriceV2.currencyCode, checkout?.subtotalPriceV2.amount) : "calcul en cours..."
    const totalTax = isBrowser && checkout?.totalTaxV2.currencyCode ? formatPrice(checkout?.totalTaxV2.currencyCode, checkout?.totalTaxV2.amount) : "calcul en cours..."
    const total = isBrowser && checkout?.totalPriceV2.currencyCode ? formatPrice(checkout?.totalPriceV2.currencyCode, checkout?.totalPriceV2.amount) : "calcul en cours..."
    const handleCheckout = () => {
        //@ts-ignore
        window.open(checkout.webUrl)
    }

    return (
        <Suspense  fallback={"chargement en cours..."}>
            <Stack sx={{padding: {xs: 0, md: modal ? "inherit" : "120px 35px 60px 35px"}}}>
                {(emptyCart || !checkout)? (
                    <Stack className={emptyStateContainer} spacing={4}>
                        <Typography fontSize={24}>Votre panier est vide</Typography>
                        <Typography>
                            Il semblerait que vous n'ayez rien trouvé pour l'instant.
                            <br/>Nous comprenons qu'il est parfois difficile de faire un choix.
                            <br/><br/>
                            Peut être que ces recommandations peuvent vous aider:
                        </Typography>
                        <Button variant={"outlined"} to="/search?s=BEST_SELLING" className={emptyStateLink} onClick={handleClick}>
                            Voir les produits populaires
                        </Button>
                    </Stack>
                ) : (
                    <>
                        <table className={table}>
                            <thead>
                            <tr>
                                <th style={{paddingBottom:25}} className={imageHeader}>Image</th>
                                <th style={{paddingBottom:25}} className={productHeader}>Produit</th>
                                <th style={{paddingBottom:25}} className={collapseColumn}>Prix</th>
                                <th style={{paddingBottom:25}}>Quantité</th>
                                <th style={{paddingBottom:25}} className={[totals, collapseColumn].join(" ")}>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {/*@ts-ignore*/}
                            {checkout?.lineItems?.map((item: any) => (
                                <LineItem item={item} key={item.id} />
                            ))}
                            <tr>
                                <td style={{paddingTop:25}}></td>
                                <td style={{paddingTop:25}}></td>
                                <td style={{paddingTop:25}}></td>
                                <td style={{paddingTop:25}}></td>
                                <td style={{paddingTop:25}}></td>
                            </tr>

                            <tr className={summary}>
                                <td className={collapseColumn}></td>
                                <td className={collapseColumn}></td>
                                <td className={collapseColumn}></td>
                                <td className={labelColumn}>Sous-total</td>
                                <td className={totals}>
                                    {subtotal}
                                </td>
                            </tr>
                            <tr className={summary}>
                                <td className={collapseColumn}></td>
                                <td className={collapseColumn}></td>
                                <td className={collapseColumn}></td>
                                <td className={labelColumn}>Taxes</td>
                                <td className={totals}>
                                    {
                                        //@ts-ignore
                                        checkout?.totalTaxV2.amount === "0.0" ? "Incluses" : totalTax
                                    }
                                </td>
                            </tr>
                            <tr className={summary}>
                                <td className={collapseColumn}></td>
                                <td className={collapseColumn}></td>
                                <td className={collapseColumn}></td>
                                <td className={labelColumn} style={{borderBottom: "1px solid white", paddingTop:5, paddingBottom:5}}>Livraison</td>
                                <td className={totals} style={{borderBottom: "1px solid white", paddingTop:5, paddingBottom:5}}>Calculé au moment du paiement</td>
                            </tr>
                            <tr className={grandTotal}>
                                <td className={collapseColumn}></td>
                                <td className={collapseColumn}></td>
                                <td className={collapseColumn}></td>
                                <td className={labelColumn} style={{fontWeight:"bold", paddingTop:5}}>Prix total</td>
                                <td className={totals} style={{fontWeight:"bold", paddingTop:5}}>
                                    {total}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        {!modal &&
                            <Box sx={{display:"flex", width:1024, maxWidth:"100%", margin:"auto", justifyContent: "flex-end"}}>
                                <Button
                                    variant={"outlined"}
                                    onClick={handleCheckout}
                                    disabled={loading}
                                    size={"large"}
                                    style={{marginTop:"50px", minWidth:300}}
                                >
                                    Commander
                                </Button>
                            </Box>

                        }
                    </>
                )}
            </Stack>
        </Suspense>
    )
}

export default CartSkeleton