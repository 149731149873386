import * as React from "react";
import {Stack} from "@mui/material";
// @ts-ignore
import { FluffyNav } from "@molecules/navigation/style";
// @ts-ignore
import { NavigationMenuPropTypes } from "@molecules/navigation/propTypesValidation";
// @ts-ignore
import { useLanguagesStateContext } from "@global/contexts/languages/languageContext";
// @ts-ignore
import {isCurrentLink} from "@global/utils/linkHeleper";
import {useLocation} from "@reach/router";
// @ts-ignore
import MegaMenu from "@molecules/navigation/megaMenu";
import {Button} from "gatsby-theme-material-ui";
import { NavigationContext } from "global/layouts/header/header";

export default function Navigation({links}:any) {
    const location = useLocation();
    const languageState = useLanguagesStateContext();
    const navigation = React.useContext(NavigationContext);
    const langSlug = languageState.languageSlug === "fr-ch" ? "" : `/${languageState.languageSlug}`;

    return (
        <FluffyNav component={"nav"}>
          <Stack direction={"row"} spacing={2}>
            {links &&
              links.map((link:any, index:any) => {
                  const currentUrl = `${langSlug}/${link.page?.slug ? link.page.slug : ""}`;
                  switch (link.page?._type) {
                      case "links":
                            return(
                                <Button
                                    key={index}
                                    variant={isCurrentLink(currentUrl, location) ? "contained" : "text"}
                                    to={`${langSlug}${link.page?.links ? link.page?.links[0].url : "/"}`}
                                    role={"link"}
                                >
                                    {link.title}
                                </Button>)
                      default:
                          return (
                              <React.Fragment key={index}>
                                  {(link.page.collectionPages?.length || link.page.subPages?.length || link.page.linkedCollections?.length) ?
                                      <><MegaMenu link={link}/></>
                                      : (
                                          <Button
                                              key={link._key}
                                              variant={isCurrentLink(currentUrl, location) ? "contained" : "text"}
                                              role={"link"}
                                              //sx={{fontWeight: isCurrentLink(currentUrl, location) ? "bold" : "normal"}}
                                              to={currentUrl}
                                              onClick={() => navigation.toggleMenu(false)}
                                          >
                                              {link.title}
                                          </Button>
                                      )
                                  }
                              </React.Fragment>
                          )
                    }
              })}
          </Stack>
        </FluffyNav>
    );
}

Navigation.propTypes = NavigationMenuPropTypes;