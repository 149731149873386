exports.components = {
  "component---src-global-templates-pages-page-js": () => import("./../../../src/global/templates/pages/page.js" /* webpackChunkName: "component---src-global-templates-pages-page-js" */),
  "component---src-global-templates-shop-collection-collection-jsx": () => import("./../../../src/global/templates/shop/collection/collection.jsx" /* webpackChunkName: "component---src-global-templates-shop-collection-collection-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-products-shopify-product-product-type-shopify-product-handle-jsx": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-shopify-product-handle-jsx" */),
  "component---src-pages-sanity-homepage-i-18-n-lang-sanity-homepage-id-index-jsx": () => import("./../../../src/pages/{SanityHomepage.i18n_lang}/{SanityHomepage._id}/index.jsx" /* webpackChunkName: "component---src-pages-sanity-homepage-i-18-n-lang-sanity-homepage-id-index-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */)
}

