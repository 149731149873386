import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import isbot from 'isbot'
import {useForm, useWatch} from 'react-hook-form'
import {FormInputCheckbox} from "../form/FormInputCheckbox";
import {IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {Info} from "@mui/icons-material";
const AgeVerification = () => {
    const [open, setOpen] = React.useState(true);
    const [crawler, setCrawler] = React.useState(true);
    const [warning, setWarning] = React.useState<string | null>(null);

    const {
        handleSubmit,
        control,
        getValues,
        setError,
        clearErrors,
        formState: { isDirty, dirtyFields,isLoading, isSubmitting, isSubmitSuccessful, errors }
    } = useForm();
    const agreement = useWatch({
        control,
        name: "age",
    })
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event: object, reason?: string) => {
        if(reason === "backdropClick" || reason === "escapeKeyDown"){
            return
        }
        if(reason === "disagree"){
            setWarning("Vous n'avez pas l'âge légal requis pour visiter ce site, veuillez quitter cette page.")
            return
        }
        window.localStorage.setItem("ageVerified", "true")
        setOpen(false);
    };

    React.useEffect(() => {
        if(!isbot(navigator.userAgent)){
            setCrawler(false)
        }
        const ageVerified = window.localStorage.getItem("ageVerified")
        if(ageVerified){
            setOpen(false)
        }
    },[])

    React.useEffect(() => {
        if(agreement){
            setWarning(null)
        }
    },[agreement])

    if(crawler) {
        return <React.Fragment/>
    }

    return (
        <>
            <Dialog
                open={open && !crawler}
                onClose={handleClose}
                disableEscapeKeyDown
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    zIndex: 1400
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Âge légal (18+)"}
                </DialogTitle>
                <DialogContent>
                    <Stack rowGap={2}>
                        <Typography fontSize={"1.1rem"}>Bienvenue sur Les Portes d'Épicures !</Typography>
                        <DialogContentText id="alert-dialog-description">
                            Pour accéder à notre site, vous devez avoir l'âge légal (18+) pour acheter de l'alcool dans votre pays de résidence. <br/><br/>
                            <span style={{color:"red"}}>Veuillez confirmer que vous avez l'âge légal pour acheter de l'alcool en cochant la case "J'ai l'âge légal" ci-dessous.</span><br/><br/>
                        </DialogContentText>
                        <Stack direction={"row"} alignItems={"center"} spacing={1} justifyContent={"flex-end"}>
                            <Tooltip
                                title={
                                    <Typography>
                                        En cochant la case "J'ai l'âge légal", vous confirmez que vous avez l'âge légal pour acheter de l'alcool dans votre pays de résidence.
                                        Nous prenons au sérieux notre responsabilité en matière de vente d'alcool et nous nous engageons à ne vendre de l'alcool qu'aux personnes de l'âge légal. Merci de votre coopération.
                                    </Typography>
                                }
                            >
                                <IconButton>
                                    <Info />
                                </IconButton>
                            </Tooltip>
                        <FormInputCheckbox
                            name={"age"}
                            control={control}
                            label={"J'ai l'âge légal"}
                        />
                        </Stack>
                        {warning && !agreement &&
                            <Typography color={"warning.main"} id="alert-dialog-description">
                                {warning}
                            </Typography>
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleClose(e, "disagree")}>Refuser</Button>
                    <Button disabled={!agreement} type={"submit"} onClick={handleClose} autoFocus>
                        Entrer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AgeVerification