import * as React from "react";
import { Box } from "@mui/material";
import { SocialNav } from "./style";
import { SocialMenuPropTypes } from "./propTypesValidation";
import { IconLink, VisuallyHidden } from "../../../global/layouts/ui/ui";
import { Facebook, Instagram, Twitch, Twitter, Youtube } from "react-feather";

const getSocialIcon = ({ service }) => {
  return socialMedia[service]?.icon
}

const getSocialName = ({ service }) => {
  return socialMedia[service]?.name
}

export default function SocialMenu({socials}) {
  return (
    <SocialNav component={"nav"} >
      <Box component={"ul"} sx={{paddingLeft: "0px"}}>
        {
          socials?.map(social => {
            const { url } = social
            return (
              url && (
                <li key={social.id}>
                  <IconLink to={url}>
                    <VisuallyHidden>{getSocialName(social)}</VisuallyHidden>
                    {getSocialIcon(social)}
                  </IconLink>
                </li>
              )
            )
          })
        }
      </Box>
    </SocialNav>
  );
}

SocialMenu.propTypes = SocialMenuPropTypes;

const socialMedia = {
  TWITTER: {
    url: "https://twitter.com",
    name: "Twitter",
    icon: <Twitter />,
  },
  INSTAGRAM: {
    url: "https://instagram.com",
    name: "Instagram",
    icon: <Instagram />,
  },
  FACEBOOK: {
    url: "https://facebook.com",
    name: "Facebook",
    icon: <Facebook />,
  },
  YOUTUBE: {
    url: "https://youtube.com",
    name: "YouTube",
    icon: <Youtube />,
  },
  TWITCH: {
    url: "https://twitch.tv",
    name: "Twitch",
    icon: <Twitch />,
  },
}