import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {CartButton} from "../../atoms/shopify/cart-button";
import {Box} from "@mui/material";
import { StoreContext } from "../../../global/contexts/store/storeContext"
import CartSkeleton from "../../templates/cart/cartSkeleton";
import {Close, Expand, OpenInFull} from "@mui/icons-material";
import {IconButton} from "gatsby-theme-material-ui";

export const CartDialog = () => {
    const { checkout, loading } = React.useContext(StoreContext)
    const items = checkout ? checkout.lineItems : []
    const quantity = items?.reduce((total: number, item: { quantity: number; }) => {return total + item.quantity}, 0)
    const emptyCart = checkout?.lineItems.length === 0
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleCheckout = () => {
        // @ts-ignore
        window.open(checkout.webUrl)
    }
    const handleClick = () => {
        setOpen((prevState) => !prevState);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <CartButton quantity={quantity} handleClick={handleClick}/>
            <Dialog
                PaperProps={{
                   sx: {width:1000, maxWidth:"100%"}
                }}
                sx={{zIndex:1302}}
                fullScreen={fullScreen}
                maxWidth={"xl"}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <Box id="responsive-dialog-title" display={"flex"} alignItems={"center"}>
                    <DialogTitle>{"Votre panier"}</DialogTitle>
                    {(!emptyCart || checkout) &&
                        <IconButton
                            onClick={handleClose}
                            to={"/cart"}
                            color={"primary"}
                            sx={{display: {xs: "none", md: "block"}, ml: "auto", height: "50px", width: "50px"}}
                        >
                            <OpenInFull/>
                        </IconButton>
                    }
                    <IconButton
                        onClick={handleClose}
                        color={"primary"}
                        sx={{ml: {xs:"auto", md:0}, mr:1, height: "50px", width: "50px"}}
                    >
                        <Close/>
                    </IconButton>
                </Box>
                <DialogContent>
                    <CartSkeleton modal handleClick={handleClick}/>
                </DialogContent>
                <DialogActions sx={{padding:2, justifyContent: {xs: "space-between", md:"flex-end"}}}>
                    <Button size={"large"} onClick={handleClose} >
                        Retour
                    </Button>
                    <Button
                        variant={"outlined"}
                        size={"large"}
                        sx={{minWidth:200}}
                        autoFocus
                        onClick={handleCheckout}
                        disabled={(loading || emptyCart || !checkout)}
                    >
                        Commander
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
