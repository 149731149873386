import * as React from "react";
import {Controller} from "react-hook-form";
import {FormInputProps} from "./FormInputProps";
import {Checkbox, FormHelperText, FormLabel, TextField} from "@mui/material";

export const FormInputCheckbox = ({name, control, rules = {}, label, textField = {}}: FormInputProps) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({
               field: {onChange, onBlur, value, name, ref },
               fieldState: {error},
               formState,
            }) => (
                <>
                    <FormLabel id="row-checkbox-group-label">{label}</FormLabel>
                    <Checkbox
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        checked={value}
                        inputRef={ref}
                    />
                    <FormHelperText sx={{ml:0}}>{error?.message}</FormHelperText>
                </>

            )}
        />
    );
};
