import * as React from "react"
// @ts-ignore
import fetch from "isomorphic-fetch"
import Client, {Checkout, Cart, ProductVariant} from "shopify-buy"
import { ApiVersion } from "@shopify/shopify-api";
import ShopifyBuy from "shopify-buy";

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_PUBLIC_STORE_URL || '',
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN || '',
    apiVersion: ApiVersion.January23,
    language: 'fr-CH'
  },
  fetch
)

export type StoreContextType = {
    removeLineItem: (checkoutID: any, lineItemID: any) => void;
    onClose: () => void;
    addVariantToCart: (variantId: any, quantity: string) => void;
    updateLineItem: (checkoutID: any, lineItemID: any, quantity: string) => void;
    isOpen: boolean;
    onOpen: () => void;
    client: ShopifyBuy;
    loading: boolean;
    checkout: Checkout | null;
    completedOrder: Checkout | null;
    cart: Cart
}

const defaultValues = {
    cart: [],
    isOpen: false,
    loading: false,
    onOpen: () => {},
    onClose: () => {},
    addVariantToCart: () => {},
    removeLineItem: () => {},
    updateLineItem: () => {},
    client,
    checkout: null,
    completedOrder: null,
};

// @ts-ignore
export const StoreContext: React.Context<StoreContextType> = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`
const sessionStorageKey = `shopify_completed_order_id`

export const StoreProvider = ({ children }: any) => {
  const [checkout, setCheckout] = React.useState<Checkout | null>(defaultValues.checkout)
  const [completedOrder, seCompletedOrder] = React.useState<Checkout | null>(defaultValues.completedOrder)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)

  const setCheckoutItem = (checkout: Checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }
  const setCompletedCheckoutItem = (checkout: Checkout) => {
    if (isBrowser) {
      sessionStorage.setItem(sessionStorageKey, checkout.id)
    }
    seCompletedOrder(checkout)
  }

  const resetCheckoutItem = () => {
    if (isBrowser) {
        // @ts-ignore
        localStorage.setItem(localStorageKey, null)
    }

    setCheckout(null)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          // @ts-ignore
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  React.useEffect(() => {
      if(checkout?.completedAt) {
          setCompletedCheckoutItem(checkout)
          resetCheckoutItem()
          return
      }
  }, [checkout])

  const addVariantToCart = (variantId: any, quantity: string) => {
    setLoading(true)

    // @ts-ignore
    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    return client.checkout
      .addLineItems(checkoutID || '', lineItemsToUpdate)
          .then((res) => {
            setCheckout(res)
            setLoading(false)
            // @ts-ignore
            if(res.userErrors?.length){
                return
            }
            setDidJustAddToCart(true)
            setTimeout(() => setDidJustAddToCart(false), 3000)
          })
          .catch((error) => {
              setLoading(false)
              console.error(error)
          })
  }

  const removeLineItem = (id: any, lineItemID: any) => {
    setLoading(true)
    // @ts-ignore
    const checkoutID = id || checkout.id
    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
        .then((res) => {
            setCheckout(res)
            setLoading(false)
        })
        .catch(e => {
            setLoading(false)
            console.error(e)
        })
  }

  const updateLineItem = (id: any, lineItemID: any, quantity: string) => {
    setLoading(true)
    // @ts-ignore
    const checkoutID = id || checkout.id
    const lineItemsToUpdate: any[] = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
        .then((res) => {
            setCheckout(res)
            setLoading(false)
        })
        .catch(e => {
            setLoading(false)
            console.error(e)
        })
  }


    return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        completedOrder,
        loading,
        // @ts-ignore
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
