import * as React from "react"
import { useContext, useEffect } from "react";
import languageReducer from "./languageReducer";

export const defaultLanguagesState = {
  id: null,
  i18n_id: null,
  i18n_lang: null,
  languageName: null,
  languageSlug: null,
  error: false,
  translations: [],
}

export const LanguagesStateContext = React.createContext(defaultLanguagesState);
export const LanguagesDispatchContext = React.createContext({});

export const LanguageProvider = ({children, pageContext}) => {
  const [state, dispatch] = React.useReducer(languageReducer, defaultLanguagesState);
  useEffect(() => {
    dispatch({
      type: "set",
      payload: {
        id: pageContext.id,
        i18n_id: pageContext.i18n_id,
        i18n_lang: pageContext.i18n_lang,
        languageName: pageContext.languageName,
        languageSlug: pageContext.languageSlug,
      }
    })
  }, [pageContext])
  useEffect(() => {
  }, [state])

  return (
    <LanguagesStateContext.Provider value={state}>
      <LanguagesDispatchContext.Provider value={dispatch}>
        {children}
      </LanguagesDispatchContext.Provider>
    </LanguagesStateContext.Provider>
  );
};

export const useLanguagesStateContext = () => {
  const context = useContext(LanguagesStateContext);
  if(context === undefined) {
    throw new Error("LanguagesStateContext cannot be accessible.");
  }
  return context;
}

export const useLanguagesDispatchContext = () => {
  const context = useContext(LanguagesDispatchContext);
  if(context === undefined) {
    throw new Error("LanguagesDispatchContext cannot be accessible.");
  }
  return context;
}