import { styled } from '@mui/material/styles';
import { colors } from "../../../colors.css";
import {Box} from "@mui/material";

export const PrivacyNav = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  "li": {
    "a": {
      textDecoration: "none",
      "p": {
        "&:hover": {
          color: `${colors.active}`
        },
      }
    },
  },
  "li:last-child": {
    marginRight: "0px",
  },
}));