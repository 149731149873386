import * as React from "react";
// @ts-ignore
import { HeaderAppBar } from "./style";
import MenuIcon from '@mui/icons-material/Menu';
import Navigation from "../../../components/molecules/navigation/navigation";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {Alert, Box, Drawer, Snackbar, Stack, Toolbar, useScrollTrigger} from "@mui/material";
// @ts-ignore
import { useLanguagesStateContext } from "../../contexts/languages/languageContext";
import { StoreContext } from "../../contexts/store/storeContext"
import {Link} from "gatsby";
import {CartDialog} from "../../../components/organisms/cart/cartDialog";
import SearchBar from "../../../components/atoms/shopify/searchBar";
import SearchModal from "../../../components/molecules/navigation/searchModal";
import {IconButton} from "gatsby-theme-material-ui";
import {User} from "react-feather";
import ErrorAlerts from "../../../components/atoms/ui/errorAlert";
import MobileNav from "../../../components/molecules/navigation/mobileNav";
import {createContext, useEffect} from "react";
import {useLocation} from "@reach/router";
import {absoluteUrl} from "../../utils/path";

export const NavigationContext = createContext({
  open: false,
  toggleMenu: (value: boolean) => {},
})
export default function Header({link, logo, navigation}:any) {
  // @ts-ignore
  const { loading, didJustAddToCart } = React.useContext(StoreContext)
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });
  const location = useLocation()
  const [menuOpen, setMenuOpen] = React.useState(false);
  const languageState = useLanguagesStateContext();
  const logoAsset = logo && getImage(logo.asset.gatsbyImageData)
  const handleDrawerToggle = (value: any) => {
    if(typeof value === "boolean"){
      setMenuOpen(value)
    } else {
      setMenuOpen(!menuOpen)
    }
  };

  useEffect(() =>{
    if(location.pathname){
      setMenuOpen(false)
    }
  },[location.pathname])

  return (
    <HeaderAppBar id={"main-menu"} position="static" elevation={trigger ? 4 : 0} sx={{backgroundColor: trigger ? "rgba(0,0,0,.8)" : "transparent", backdropFilter: trigger ? "blur(4px)" : "unset", maxWidth:"100%", py: {xs:2, md:2}, px: {xs:3, md:3}}}>
      <NavigationContext.Provider value={{open:menuOpen, toggleMenu:handleDrawerToggle}}>
        <Toolbar disableGutters>
          <Box sx={{position:"absolute", top:-10, display: { xs: 'none', md: 'flex' }}} id={"menu-logo"}>
            {logo && (
              <Link to={`/${languageState.languageSlug === "fr-ch" ? "" : languageState.languageSlug}`} onClick={() => handleDrawerToggle(false)}>
                <GatsbyImage
                  alt={logo.alt || ``}
                  // @ts-ignore
                  image={logoAsset}
                />
              </Link>
              )}
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            {logo && (
              <Link to={`/${languageState.languageSlug === "fr-ch" ? "" : languageState.languageSlug}`}>
                <GatsbyImage
                  alt={logo.alt || ``}
                  image={logoAsset}
                />
              </Link>
            )}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Navigation links={navigation?.menuContentList}/>
          </Box>
          <Box position="absolute" right={0}>
            <Stack direction={"row"} alignItems={"center"} spacing={{xs:1, md:2}}>
              {!location.pathname.includes("/search") &&
                  <>
                    <SearchBar/>
                    <SearchModal/>
                  </>
              }
              <CartDialog/>
              <IconButton
                  to={`${absoluteUrl(process.env.GATSBY_SHOPIFY_PUBLIC_STORE_URL)}/account`}
                  // @ts-ignore
                  target={"_blank"}
              >
                <User/>
              </IconButton>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
                <IconButton
                    size="large"
                    aria-label="menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleDrawerToggle}
                    color="inherit"
                    sx={{ml: "auto"}}
                >
                  <MenuIcon />
                </IconButton>
                <MobileNav
                    logo={logo}
                    links={navigation?.menuContentList}
                    open={menuOpen}
                    drawerWidth={"100%"}
                />
              </Box>
            </Stack>
          </Box>
        </Toolbar>
        <Snackbar open={loading || didJustAddToCart} anchorOrigin={{ vertical:"top", horizontal:"right"}} sx={{top:{xs:100, md: 100}, bottom:{xs:"unset", md: "unset"}, maxWidth: "calc(100% - 25px)"}}>
          {(loading && !didJustAddToCart) ? (
              <Alert severity="info" sx={{ width: '100%' }}>
                Mise à jour de votre panier..
              </Alert>
          ) : (
              <Alert severity="success" sx={{ width: '100%' }}>
                Produit ajouté au panier !
              </Alert>
          )}
        </Snackbar>
        <ErrorAlerts/>
      </NavigationContext.Provider>
    </HeaderAppBar>
  );
}