import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import {navigate} from "gatsby";
import {Close} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import queryString from "query-string";
import {NavigationContext} from "../../../global/layouts/header/header";
import {useLocation} from "@reach/router";
import {getQueryParams, queryParamsEventEmitter, setQueryParams} from "react-use-query-param-string";
import {atom, useAtom} from "jotai";
import { useHydrateAtoms } from 'jotai/utils'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    //borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    marginBottom:0,
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        marginBottom:0,
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        fontFamily: theme.typography.body2.fontFamily,
    },
}));

type SearchBarProps = {
    display?:boolean,
    autofocus?:boolean,
    live?:boolean,
    isInNav?:boolean,
    defaultTerm?:string,
    placeholder?: null | string,
    setFilters?: (filters: any) => any
}

export const searchAtom = atom<string | (string | null)[]>("")

export const setSearchAtom = atom(
    null, // it's a convention to pass `null` for the first argument
    (
        get,
        set,
        update: string
    ) => {
        set(searchAtom, update)
    }
)
export const triggerSearchAtom = atom(
    null, // it's a convention to pass `null` for the first argument
    async (
        get,
        set,
        live: boolean,
        searchTerm: string | (string | null)[],
        navigation:  {open: boolean, toggleMenu: (value: boolean) => void},
        isInNav: boolean
    ) => {
        const parsedTerms = queryString.stringify({q: searchTerm || undefined})
        const currentLocation = location.pathname
        if(!live){
            await navigate(`/search?${parsedTerms}`)
            if(isInNav) {
                navigation.toggleMenu(false)
            }
        }
        if(currentLocation.includes("/search")) {
            setQueryParams({ q: searchTerm });
        }
        set(searchAtom, searchTerm)
    }
)

const SearchBar = ({
   display = false,
   autofocus = false,
   live = false,
   isInNav= false,
   defaultTerm = "",
   placeholder =  null,
   setFilters = (filters: any) => filters
}: SearchBarProps) => {
    const initialValue = getQueryParams()?.q ?? defaultTerm
    useHydrateAtoms([
        [searchAtom, initialValue]
    ] as const)
    const [searchTerm, setSearchTerm] = useAtom(searchAtom)
    const navigation = React.useContext(NavigationContext);
    const location = useLocation()
    //const search = React.useContext(SearchContext)
    const [, handleSearch] = useAtom(triggerSearchAtom)
    const [, setValue] = useAtom(setSearchAtom)

    queryParamsEventEmitter.addListener('update', () => {
        const params = getQueryParams();
        // @ts-ignore
        setValue(params?.q ?? "")
    })

    React.useEffect(() => {
        const currentLocation = location.pathname
        if(live){
            React.startTransition(() => {
                setFilters((filters: any) => ({
                    ...filters,
                    term: searchTerm
                }))
            });
        }
    },[searchTerm])

    return (
        <Search sx={{display: {xs:display ? "block" : "none", md: !isInNav && navigation.open ? "none" : "block"}}}>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                autoFocus={autofocus}
                value={searchTerm}
                size={"medium"}
                onChange={(e) => {
                    setSearchTerm(e.currentTarget.value)
                }}
                onKeyUp={async (e) => {
                    if (e.key === 'Enter') {
                        await handleSearch(
                            live,
                            searchTerm,
                            navigation,
                            isInNav
                        )
                    }
                }}
                placeholder={placeholder ?? "Recherche"}
                inputProps={{ 'aria-label': 'search' }}
                endAdornment={searchTerm !== "" && <IconButton color={"primary"} onClick={() => setSearchTerm("")}><Close/></IconButton>}
            />
        </Search>
    );
}

export default SearchBar
