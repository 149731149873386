import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from "@mui/material/DialogTitle";
import SearchBar, {searchAtom, triggerSearchAtom} from "../../atoms/shopify/searchBar";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {Close} from "@mui/icons-material";
import {IconButton} from "gatsby-theme-material-ui";
import {Stack, Typography} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {useAtom} from "jotai";
import {NavigationContext} from "../../../global/layouts/header/header";


const SearchModal = () => {
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = useAtom(searchAtom)
    const navigation = React.useContext(NavigationContext);
    const [, handleSearch] = useAtom(triggerSearchAtom)

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <Box display={{xs:"block", md:"none"}}>
            <IconButton color={"primary"} onClick={handleOpen}><SearchIcon /></IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                PaperProps={{
                    sx: {position: "absolute", top:100},
                }}
            >
                <Box width={"100%"} display={"flex"} alignItems={"center"}>
                    <DialogTitle>{"Recherche"}</DialogTitle>
                    <IconButton sx={{ml:"auto", mr:1, height:"50px", width:"50px"}} color={"primary"} onClick={handleClose}><Close/></IconButton>
                </Box>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography>{"Recherchez un vin, un assemblage ou un vigneron"}</Typography>
                        <SearchBar display autofocus/>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Retour</Button>
                    <Button
                        onClick={async () => {
                            await handleSearch(
                                false,
                                searchTerm,
                                navigation,
                                false
                            )
                            handleClose()
                        }}
                    >
                        Rechercher
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default SearchModal