import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {Close, ExpandLess, ExpandMore} from "@mui/icons-material";
import {IconButton} from "gatsby-theme-material-ui";
import MegaMenuContent from "../../atoms/navigation/megaMenuContent";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {Fade, Typography} from "@mui/material";
import {useLocation} from "@reach/router";
import {useEffect} from "react";
import {NavigationContext} from "../../../global/layouts/header/header"
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {


    return(
        <Slide {...props}
               direction="down"
               ref={ref}
               timeout={{
                   appear: 0,
                   enter: 500,
                   exit: 500,
               }}
        >
            <Box width={"100%"} height={"100%"}>
            <Fade
                {...props}
                timeout={{
                    appear: 1000,
                    enter: 1000,
                    exit: 500,
                }}

            >
                {props.children}
            </Fade>
            </Box>
        </Slide>
    )
});


const MegaMenu = ({link}: any) => {
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const containerRef = React.useRef(null);
    const navigation = React.useContext(NavigationContext);
    const toggleMenu = () => navigation.toggleMenu(!navigation.open);
    const handleClose = () => navigation.toggleMenu(false);

    useEffect(()=> {
        if(location.pathname){
            setOpen(false)
        }
    },[location.pathname])

    return (
        <>
            <Button
                variant={location.pathname.includes("/vins") ? "contained" : "text"}
                role={"link"}
                id="basic-button"
                aria-controls={navigation.open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={navigation.open ? 'true' : undefined}
                onClick={toggleMenu}
                endIcon={navigation.open ? <ExpandLess/> : <ExpandMore/>}
            >
                {link.title}
            </Button>
            <Dialog
                open={navigation.open}
                ref={containerRef}
                TransitionComponent={Transition}
                maxWidth={"lg"}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                //disableScrollLock={true}
                sx={{display: {xs: 'none', sm: 'block'},}}
                PaperProps={{
                    sx: {position: "absolute", top:-30, width:"100%", maxWidth:"100%", padding:12},
                }}
            >
                <Box width={"100%"} display={"flex"} alignItems={"center"}>
                    {/*<DialogTitle width={"100%"} display={"flex"} justifyContent={"center"}>
                        <Typography variant="h2" textAlign={"center"} component={"div"} fontWeight={400}>Les Portes
                            D'Épicure</Typography>
                    </DialogTitle>*/}
                    <IconButton sx={{ml:"auto", mr:1, height:"50px", width:"50px"}} color={"primary"} onClick={handleClose}><Close/></IconButton>
                </Box>
                <DialogContent sx={{pt:0}}>
                    <MegaMenuContent link={link} handleClose={handleClose}/>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default MegaMenu